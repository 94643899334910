




























































@import 'src/styles/mixin.scss';
.app-wrapper {
	@include clearfix;
	position: relative;
	// height: 100%;
	// width: 100%;

	height: calc(100vh);
	// min-width: 1440px;
	// max-width: 1440px;
	min-width: 1400px;
	max-width: 1400px;
	margin: auto;
}

@media screen and (min-width: 1490px) {
	.app-wrapper {
		@include clearfix;
		position: relative;
		// height: 100%;
		// width: 100%;

		height: calc(100vh);
		min-width: 1440px;
		max-width: 1440px;
		// min-width: 1400px;
		// max-width: 1400px;
		margin: auto;
	}

	.navheader {
		width: 1440px;
	}
}
@media screen and (max-width: 1490px) {
	.app-wrapper {
		@include clearfix;
		position: relative;
		// height: 100%;
		// width: 100%;

		height: calc(100vh);
		// min-width: 1440px;
		// max-width: 1440px;
		min-width: 1400px;
		max-width: 1400px;
		margin: auto;
	}

	.navheader {
		width: 1400px;
	}
}

.el-divider--horizontal {
	margin: 0;
}

.navheader {
	height: 80px;
	position: fixed;
	background: white;
	display: flex;
	justify-content: space-between;
	z-index: 1010;
}
