@import './variables.scss';
@import './mixin.scss';
// @import './transition.scss';
@import './element-ui.scss';
@import './sidebar.scss';

body {
	background: #f5f7fa;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
		Microsoft YaHei, Arial, sans-serif;
}

html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

div:focus {
	outline: none;
}

a:focus,
a:active {
	outline: none;
}

a,
a:focus,
a:hover {
	cursor: pointer;
	color: inherit;
	text-decoration: none;
}

.clearfix {
	&:after {
		visibility: hidden;
		display: block;
		font-size: 0;
		content: ' ';
		clear: both;
		height: 0;
	}
}

//main-container全局样式
.app-main {
	min-height: 100%;
	// background: white;
	background: white;
}

.app-container {
	padding: 20px;
}

//搜索栏样式
.filter-container {
}

//操作栏样式
.operate-container {
	margin-top: 20px;
}

.operate-container .btn-add {
	float: right;
}

//表格栏样式
.table-container {
	margin-top: 20px;
}

//批量操作栏样式
.batch-operate-container {
	display: inline-block;
	margin-top: 20px;
}

//分页栏样式
.pagination-container {
	display: inline-block;
	float: right;
	margin-top: 20px;
}

//添加、更新表单样式
.form-container {
	position: absolute;
	left: 0;
	right: 0;
	width: 720px;
	padding: 35px 35px 15px 35px;
	margin: 20px auto;
}

//主标题
.font-extra-large {
	font-size: 20px;
	color: #303133;
}

//标题
.font-title-large {
	font-size: 18px;
	color: #303133;
}

//小标题
.font-title-medium {
	font-size: 16px;
	color: #303133;
}

//正文
.font-medium {
	font-size: 16px;
	color: #606266;
}

//正文
.font-small {
	font-size: 14px;
	color: #606266;
}

//正文（小）
.font-extra-small {
	font-size: 13px;
	color: #606266;
}

.color-main {
	color: #409eff;
}

.color-success {
	color: #67c23a;
}

.color-warning {
	color: #e6a23c;
}

.color-danger {
	color: #f56c6c;
}

.color-info {
	color: #909399;
}
