.sidebar-container /deep/ div ul div a li:hover {
  background-color: #f5f6fa !important;
  border-radius: 8px;
}
.brand-select-container {
  margin-bottom: 12px;
}
.sidebar-container /deep/ div ul div a.router-link-exact-active.router-link-active li {
  background-color: #f5f6fa !important;
  border-radius: 8px;
}
#sidebar /deep/ div ul div li.el-submenu.is-active.is-opened div {
  background: #f5f6fa !important;
  border-radius: 8px 8px 0px 0px;
}
#sidebar /deep/ div ul div li.el-submenu.is-active.is-opened ul a li {
  background: #f5f6fa !important;
}
#sidebar /deep/ div ul div li.el-submenu.is-active.is-opened ul a:last-child li {
  border-radius: 0px 0px 8px 8px;
}
 /deep/ li.el-submenu.is-active.is-opened ul a.router-link-exact-active.router-link-active li::before {
  content: '';
  /* 在标题前面插入文字 并设置样式 */
  width: 3px;
  height: 8px;
  position: absolute;
  top: 9px;
  left: 32px;
  border-radius: 1.5px;
  background: #2650f5;
}
 /deep/ .el-submenu__icon-arrow {
  right: 10px;
}
 /deep/ .el-menu-item {
  padding-left: 8px !important;
}
 /deep/ .el-submenu__title {
  padding-left: 8px !important;
  padding-right: 0 !important;
}
 /deep/ .el-submenu .el-menu-item {
  padding-left: 41px !important;
}
.brand-container {
  display: flex;
  align-items: center;
}
.brand-icon {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-right: 8px;
}
.el-brand-icon {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 8px;
}
.selected-brand-icon {
  margin-top: 3px;
}
 /deep/ .el-input--prefix .el-input__inner {
  padding-left: 45px;
}
 /deep/ .el-select .el-input input:hover {
  background: #f5f6fa;
  border-radius: 20px;
}
 /deep/ .el-input input {
  background: white;
  border-radius: 8px;
}
 /deep/ .el-input__inner {
  border: none;
}
