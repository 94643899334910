#app {

	@media screen and (min-width: 1490px) {
		.main-container {
			min-height: 100%;
			transition: margin-left 0.28s;
			margin-left: 222px;
			// margin-left: 172px;
	
			.el-menu.el-menu--horizontal {
				border-bottom: 0px;
			}
		}
	}
	@media screen and (max-width: 1490px) {
		.main-container {
			min-height: 100%;
			transition: margin-left 0.28s;
			// margin-left: 222px;
			margin-left: 172px;
	
			.el-menu.el-menu--horizontal {
				border-bottom: 0px;
			}
		}
	}

	.sidebar-logo {
		height: 64px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.submenu-title-noDropdown {
		margin-bottom: 8px;
	}

	@media screen and (min-width: 1490px) {
		// 侧边栏
		.sidebar-container {
			.horizontal-collapse-transition {
				transition: 0s width ease-in-out, 0s padding-left ease-in-out,
					0s padding-right ease-in-out;
			}
			transition: width 0.28s;
			width: 222px !important;
			height: 100%;
			position: fixed;
			// position: absolute;
			font-size: 0px;
			top: 0;
			bottom: 0;
			// left: 0;
			z-index: 1001;
			overflow: hidden;
			a {
				display: inline-block;
				width: 100%;
			}
			.svg-icon {
				margin-right: 10px;
				width: 24px;
				height: 24px;
			}
			.el-menu {
				border: none;
				width: 100% !important;
			}
			.menu-wrapper {
				margin: 16px 16px 0 16px;
				font-family: PingFangSC-Semibold;
				font-size: 14px;
			}
		}
	}
	@media screen and (max-width: 1490px) {
		// 侧边栏
		.sidebar-container {
			.horizontal-collapse-transition {
				transition: 0s width ease-in-out, 0s padding-left ease-in-out,
					0s padding-right ease-in-out;
			}
			transition: width 0.28s;
			// width: 222px !important;
			width: 172px !important;
			height: 100%;
			position: fixed;
			// position: absolute;
			font-size: 0px;
			top: 0;
			bottom: 0;
			// left: 0;
			z-index: 1001;
			overflow: hidden;
			a {
				display: inline-block;
				width: 100%;
			}
			.svg-icon {
				margin-right: 10px;
				width: 24px;
				height: 24px;
			}
			.el-menu {
				border: none;
				width: 100% !important;
			}
		}
	}

	.hideSidebar {
		.sidebar-container {
			width: 36px !important;
		}
		.main-container {
			margin-left: 36px;
		}
		.submenu-title-noDropdown {
			padding-left: 10px !important;
			position: relative;
			.el-tooltip {
				padding: 0 10px !important;
			}
		}
		.el-submenu {
			& > .el-submenu__title {
				padding-left: 10px !important;
				& > span {
					height: 0;
					width: 0;
					overflow: hidden;
					visibility: hidden;
					display: inline-block;
				}
				.el-submenu__icon-arrow {
					display: none;
				}
			}
		}
	}

	.sidebar-container .nest-menu .el-submenu > .el-submenu__title,
	.sidebar-container .el-submenu .el-menu-item {
		min-width: 180px !important;
		background-color: $subMenuBg !important;
	}

	.el-menu--collapse .el-menu .el-submenu {
		min-width: 180px !important;
	}

	//适配移动端
	.mobile {
		.main-container {
			margin-left: 0px;
		}
		.sidebar-container {
			top: 50px;
			transition: transform 0.28s;
			width: 180px !important;
		}
		&.hideSidebar {
			.sidebar-container {
				transition-duration: 0.3s;
				transform: translate3d(-180px, 0, 0);
			}
		}
	}

	.withoutAnimation {
		.main-container,
		.sidebar-container {
			transition: none;
		}
	}
}
