






























































// <style lang="scss" scoped>
@import '/src/base.less';

.el-divider--horizontal {
	margin: 0;
}

.qrcode {
	width: 300px;
	height: 300px;
}

.navbar {
	border-radius: 0px !important;

	.title-label {
		display: inline-block;
		height: 60px;
		line-height: 60px;
		position: absolute;
		left: 40px;
		// color: rgba(0, 0, 0, 0.85);
		color: #1d1d2d;
		font-weight: bold;
		font-size: 20px;
	}

	.screenfull {
		position: absolute;
		right: 90px;
		top: 16px;
		color: red;
	}

	.title-container {
		width: 100%;
		position: absolute;
		top: 80px;
		height: 64px;

		background-color: @base-app-title-container-bg;
	}

	.customer-service {
		margin: 20px 35px 20px 20px;
		display: flex;
		align-items: center;
		.customer-label {
			margin-left: 8px;
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #646474;
			line-height: 20px;
		}
	}

	.animation {
		animation: loading 0.75s linear infinite forwards;
	}

	@keyframes loading {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	.avatar-container {
		height: 64px;
		display: inline-block;
		position: fixed;
		margin-left: 915px;

		.avatar-wrapper {
			cursor: pointer;
			position: relative;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			.user-avatar {
				width: 24px;
				height: 24px;
				border-radius: 12px;
			}

			.user-name {
				padding: 0 3px 0 8px;
			}

			.el-icon-caret-bottom {
				position: absolute;
				right: -15px;
				font-size: 12px;
			}
		}
	}
}
