






































































































































































































































































































































































































































































































































































/deep/ .el-upload--picture-card {
    width: 56px;
    height: 56px;
    line-height: 64px;
    background: #F9F9F9;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 56px;
    height: 56px;
}

