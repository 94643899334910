body {
  background: #f9f9fa;
}
.navbar .title-label {
  color: #1D1D2D;
}
.app-liveSchedule-backgroundPrintPopover {
  min-width: 68px;
  min-height: 39px;
  color: #606266;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #646474;
  border-radius: 6px;
}
.el-divider {
  background: #F2F2F5;
}
.el-table__header-wrapper .cell {
  color: #1D1D2D;
}
.el-table__fixed-header-wrapper .cell {
  color: #1D1D2D;
}
.el-table__fixed-body-wrapper .el-table__cell {
  background: #f9f9fa;
}
.el-date-editor {
  background: #f9f9fa;
}
.el-checkbox .el-checkbox__inner {
  background: #f9f9fa;
}
.el-date-range-picker__content.is-left {
  border-right: 1px solid #F2F2F5;
}
.el-popover {
  color: #1D1D2D;
}
.el-dialog {
  border-radius: 12px;
}
.el-loading-mask {
  background-color: #f9f9fa;
}
.el-tooltip__popper.is-light {
  border-color: #FFF;
}
