 //to reset element-ui default css
.el-upload {
  input[type="file"] {
    display: none !important;
  }
}

.el-upload__input {
  display: none;
}

//暂时性解决diolag 问题 https://github.com/ElemeFE/element/issues/2461
.el-dialog {
  transform: none;
  left: 0;
  position: relative;
  margin: 0 auto;
}

//element ui upload
.upload-container {
  .el-upload {
    width: 100%;
    .el-upload-dragger {
      width: 100%;
      height: 200px;
    }
  }
}
