






































































.qiuwei-logo {
	width: 216px;
	height: 48px;
	position: fixed;
	z-index: 1004;
	top: 16px;
	margin-left: 16px;
}

.qrcode {
	width: 300px;
	height: 300px;
}

.avatar-container {
	height: 80px;
	display: inline-block;
	position: absolute;
	right: 32px;
	// position: fixed;
	// margin-left: 915px;

	.customer-service {
		margin: 20px 35px 20px 20px;
		display: flex;
		align-items: center;
		.customer-label {
			margin-left: 8px;
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #646474;
			line-height: 20px;
		}
	}

	.avatar-wrapper {
		position: relative;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.user-avatar {
			width: 24px;
			height: 24px;
			border-radius: 12px;
		}

		.level-icon {
			width: 40px;
			height: 16px;
			margin-right: 32px;
		}

		.logout-label {
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #909099;
			text-align: right;
			line-height: 20px;
		}

		.user-name {
			padding: 0 3px 0 8px;
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #646474;
			text-align: right;
			line-height: 20px;
		}

		.el-icon-caret-bottom {
			position: absolute;
			right: -15px;
			font-size: 12px;
		}
	}
}

.el-divider--horizontal {
	margin: 0;
}

.header-divider {
	position: fixed;
	top: 80px;
	width: 100%;
}

@media screen and (min-width: 1490px) {
	.header-divider {
		width: 1440px;
	}
}

@media screen and (max-width: 1490px) {
	.header-divider {
		width: 1400px;
	}
}
