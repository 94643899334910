
























































@import '../../styles/variables.scss';

.scroll-container {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: white;
	.scroll-wrapper {
		position: absolute;
		width: 100% !important;
		// padding: 0 8px 0 16px;
	}
}
